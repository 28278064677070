* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

#app {
  height: 100%;
  overflow: hidden;
}

.ShowsIndex {
  height: 100%;
  overflow: hidden;
  padding: 4vw;
  display: grid;
  gap: 2vw;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;

  .show {
    display: flex;
    align-items: center;
    justify-content: center;
    container: foo / size;

    .container {
      background: #CCC;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 0 3cqw 8cqw rgba(0, 0, 0, 0.5);
      --ratio: 4/3;
      aspect-ratio: var(--ratio);
      max-width: min(100cqw, 100cqh * var(--ratio));
      max-height: min(100cqw, 100cqh);
      width: 100%;
      z-index: 0;
      position: relative;

      .tv-overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .thumb {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.VideoPlayer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;

  video {
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    background: black;
  }

  .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font: 2vw monospace;
    transition: opacity 0.5s;
  }

  .overlay div {
    width: 6em;
    text-align: center;
  }
}
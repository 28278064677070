* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

#app {
  height: 100%;
  overflow: hidden;
}

.ShowsIndex {
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  gap: 2vw;
  height: 100%;
  padding: 4vw;
  display: grid;
  overflow: hidden;
}

.ShowsIndex .show {
  justify-content: center;
  align-items: center;
  display: flex;
  container: foo / size;
}

.ShowsIndex .show .container {
  --ratio: 4 / 3;
  aspect-ratio: var(--ratio);
  max-width: min(100cqw, 100cqh * var(--ratio));
  z-index: 0;
  background: #ccc;
  border-radius: 6px;
  width: 100%;
  max-height: min(100cqw, 100cqh);
  position: relative;
  overflow: hidden;
  box-shadow: 0 3cqw 8cqw #00000080;
}

.ShowsIndex .show .container .tv-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ShowsIndex .show .container .thumb {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.VideoPlayer {
  z-index: 1;
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.VideoPlayer video {
  z-index: 0;
  object-fit: contain;
  background: #000;
  width: 100%;
  height: 100%;
}

.VideoPlayer .overlay {
  z-index: 1;
  color: #fff;
  background: #00000080;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  width: 100%;
  height: 100px;
  font: 2vw monospace;
  transition: opacity .5s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.VideoPlayer .overlay div {
  text-align: center;
  width: 6em;
}
/*# sourceMappingURL=index.19465eff.css.map */
